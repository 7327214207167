// Image variables

$login-bg: url(../img/login-bg.jpg);
$line-pattern: url(../img/line-pattern.png);
$reset-icon: url(../img/reset-icon.png);
$thumb-icon: url(../img/thumb-icon.png);
$sound-icon: url(../img/sound-icon.png);
$sound-icon-1: url(../img/sound_1-icon.png);
$sound-icon-2: url(../img/sound_2-icon.png);
$print-icon: url(../img/print-icon.png);
$download-icon: url(../img/download-icon.png);
$share-icon: url(../img/share-icon.png);
$list-img: url(../img/list.png);
