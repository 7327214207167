// Footer

.footer,
footer {
  padding: 25px 0;
  color: $white;
  background-color: $black;
  @include mobile {
    * {
      text-align: center; }
    .copyright {
      margin-top: $small-padding; } }

  * {
    color: $white; }

  ul,
  p {
    margin: 0;
    font-size: $font-size-small; }

  a {
    text-decoration: underline;
    @include hover-focus {
      color: $white; } } }
