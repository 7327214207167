// Typo

body {
  font-family: $default-font-family;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: $font-weight-bold; }

h1 {
  font-size: $font-size-h1;
  font-weight: $font-weight-extrabold; }

h2 {
  font-size: $font-size-h2;
  font-weight: $font-weight-extrabold; }

h3 {
  font-size: $font-size-h3; }

h4 {
  font-size: $font-size-h4; }

h5 {
  font-size: $font-size-h5; }

h6 {
  font-size: $font-size-h6; }

p {
  font-size: $font-size-normal; }

a {
  color: $blue;
  text-decoration: underline;
  @include hover-focus {
    text-decoration: none; } }

ul {
  list-style-image: $list-img; }

.text-red {
  color: $chart-red !important; }

.text-green {
  color: $chart-green !important; }

.text-yellow {
  color: $chart-yellow !important; }

.text-blue {
  color: $chart-blue !important; }

.text-highlighted {
  color: $gold !important; }

.link-arrow {
  position: relative;
  padding-right: $small-padding + $small-padding/2;
  &::before {
    content: '\203A';
    position: absolute;
    right: 0;
    font-size: 1.5em;
    line-height: 0.8; } }

// Icons
.icon-thumbs-up,
.icon-thumbs-down {
  display: inline-block;
  width: $thumb-icon-width;
  height: $thumb-icon-height;
  background-image: $thumb-icon; }

.icon-thumbs-down {
  @include transform(scaleY(-1)); }

.help-icon {
  display: inline-block;
  width: $help-icon-size;
  height: $help-icon-size;
  line-height: $help-icon-size;
  font-weight: $font-weight-extrabold;
  color: $white;
  background-color: $blue;
  border-radius: 50%; }

.icon-v {
  display: inline-block;
  min-width: $icon-v-size;
  padding-top: $icon-v-size + ($small-padding/2);
  padding-left: $small-padding;
  padding-right: $small-padding;
  background-position: center top;
  background-repeat: no-repeat;
  &.icon-print {
    background-image: $print-icon; }
  &.icon-download {
    background-image: $download-icon; }
  &.icon-share {
    background-image: $share-icon; } }
