// Header

.header,
header {
  height: $header-height;
  line-height: $header-height;
  vertical-align: middle;
  color: $white;
  background-color: $normal-black;
  position: relative;
  z-index: 10;
  @include mobile {
    text-align: center; }

  &.login-header {
    background-color: rgba($black, 0.5); }

  .logo-container,
  h3 {
    display: inline-block;
    line-height: normal;
    vertical-align: middle; }

  h3 {
    margin: 0;
    text-align: right; } }
