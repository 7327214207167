// Form Elements

.form-group {
  position: relative;

  ul {
    &:not(.list-inline) {
      > li {
        .checkbox,
        .radio {
          margin: 0; }
        + li {
          margin-top: $small-padding; } } }
    &.list-block-mobile {
      > li {
        @include mobile {
          display: block; }
        + li {
          @include mobile {
            margin-top: $small-padding; } } } } }

  &.form-group-inline {
    .control-label,
    .form-control {
      display: inline-block;
      width: auto; } } }

// form group list
.form-group-list {
  counter-reset: number;
  position: relative;
  .form-group {
    &::before {
      counter-increment: number;
      content: counter(number) '.';
      font-size: inherit;
      font-weight: $font-weight-bold;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0; }
    > p {
      padding-left: $normal-padding; } } }

// Inputs
.form-control {
  height: $input-height;
  font-size: $font-size-normal;
  color: $normal-black;
  background-color: $white;
  border: 2px solid $light-gray;
  border-radius: 0;
  box-shadow: none;
  &:focus {
    //vborder-color: $orange
    box-shadow: none; }
  &.err {
    border-color: $orange; } }

// textarea
textarea.form-control {
  resize: vertical; }

// Label
.control-label {}

// Input with label inside
.input-label {
  position: relative;
  background-color: $white;
  .control-label {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    // form-control has border 2px
    margin-top: $input-padding + 2;
    margin-left: $input-padding + 2;
    z-index: 0;
    @include transition(margin 0.2s ease-in, font-size 0.2s ease-in); }
  .form-control {
    position: relative;
    background: none;
    z-index: 1; }
  &.focus {
    .control-label {
      font-size: $font-size-smaller;
      margin-top: 0; } } }

.selectbox {
  display: block;
  position: relative;
  height: $input-height;
  background-color: $white;
  border: 2px solid $light-gray;
  box-sizing: border-box;

  .form-control {
    height: $input-height - 4;
    padding-right: $selectbox-arrow-size + $small-padding;
    border: 0 none;
    background: none;
    position: relative;
    z-index: 1;
    @include appearance-none;
    &::-ms-expand {
      display: none; } }

  &::before {
    content: '';
    display: block;
    width: $selectbox-arrow-size;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $dark-smoke;
    z-index: 0; }

  &::after {
    content: '';
    display: block;
    @include arrow-down($selectbox-arrow-width/2, $selectbox-arrow-height, $white);
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -($selectbox-arrow-height/2);
    margin-right: $small-padding;
    z-index: 0; }

  &.selectbox-inline {
    display: inline-block; }

  &.focus {
    border-color: $light-gray; }

  &.has-error {
    border-color: $orange; }

  &.disabled {
    &::after {
 } } }      //border-top-color: rgba($dark-gray, 0.5)

// Buttons
.btn {
  height: $btn-height;
  line-height: $btn-height;
  padding: 0 $btn-padding;
  font-family: $header-font-family;
  font-size: $font-size-normal;
  text-decoration: none;
  outline: none;
  @include transition(background-color 0.25s ease-in);
  @include hover-focus {
    outline: none; }

  &.btn-primary {
    color: $white;
    background-color: $orange;
    border: 0 none;
    box-shadow: 2px 2px 0px $dark-orange;
    border-radius: $btn-height;
    @include hover-focus {
      background-color: $dark-orange; } }

  &.btn-secondary {
    color: $white;
    background-color: $gray;
    border: 0 none;
    box-shadow: 2px 2px 0px $dark-gray;
    border-radius: $btn-height;
    @include hover-focus {
      background-color: $dark-gray; }
    &.disabled {
      background-color: $gray1;
      box-shadow: 2px 2px 0px $dark-gray1; } }

  &.btn-link {
    color: $blue;
    text-decoration: underline;
    @include hover-focus {
      text-decoration: none; } }

  &.btn-reset {
    background-image: $reset-icon;
    background-repeat: no-repeat;
    background-position: $normal-padding center; } }

// Checkbox
.checkbox {
  display: inline-block;
  font-weight: $font-weight-normal;
  padding-left: $checkbox-size + $small-padding;
  position: relative;
  input[type='checkbox'] {
    display: none;
    + span {
      display: inline-block;
      width: $checkbox-size;
      height: $checkbox-size;
      line-height: $checkbox-size;
      text-align: center;
      vertical-align: middle;
      background-color: $white;
      border: 2px solid $light-gray;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 2px;
      @include transition(border-color 0.25s ease-in);
      &::before {
        content: '\e013';
        opacity: 0;
        font-family: $bs-glyphicon;
        font-size: 0.7em;
        line-height: 1.3;
        vertical-align: top;
        color: $normal-black;
        @include transition(opacity 0.25s ease-in); } }

    &:checked {
      + span {
        //border-color: $normal-black
        &::before {
          opacity: 1; } } } }

  &.err {
    input[type='checkbox'] {
      + span {
        border-color: $orange; } } } }

// Radio button
.radio {
  display: inline-block;
  min-height: $radio-size;
  font-weight: $font-weight-normal;
  padding-left: $radio-size + $small-padding;
  position: relative;
  input[type='radio'] {
    display: none;
    + span {
      display: inline-block;
      width: $radio-size;
      height: $radio-size;
      line-height: $radio-size;
      text-align: center;
      vertical-align: middle;
      background-color: $white;
      border: 2px solid $light-gray;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 3px;
      @include transition(border-color 0.25s ease-in);
      &::before {
        content: '';
        opacity: 0;
        display: block;
        width: $radio-inner-size;
        height: $radio-inner-size;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -($radio-inner-size/2);
        margin-left: -($radio-inner-size/2);
        @include transition(opacity 0.25s ease-in); } }

    &:checked {
      + span {
        //border-color: $orange
        &::before {
          opacity: 1;
          background-color: $gold; } } } }

  &.err {
    input[type='radio'] {
      + span {
        border-color: $orange; } } } }

.err-msg {
  color: $orange; }
