// Survey
$survey-title-triangle: 123px;
$survey-title-height: 53px;
$survey-form-group-width: 560px;

.survey {
  padding-bottom: $big-padding;
  text-align: center;

  .survey-header {
    padding-top: $normal-padding*2;
    padding-bottom: $big-padding + $small-padding/2;
    background-color: $lighter-smoke;

    h1 {
      text-transform: uppercase;
      letter-spacing: 5px; } }

  .survey-footer {
    padding-top: $normal-padding;
    padding-bottom: $normal-padding;
    border-top: 1px solid rgba($black, .12);

    .list-inline {
      margin-bottom: 0;
      > li {
        @include mobile {
          display: block;
          + li {
            margin-top: $small-padding; } } } } }

  .survey-progress {
    vertical-align: middle;
    .progress-container {
      display: inline-block;
      width: 80%;
      height: $progress-icon-height;
      vertical-align: middle;
      padding-right: $progress-icon-width + $small-padding;
      position: relative;
      @include mobile {
        padding-right: 0; }
      .progress {
        height: $progress-bar-height;
        margin-top: ($progress-icon-height - $progress-bar-height)/2;
        margin-bottom: 0;
        padding: 4px;
        background-color: $white;
        border: 1px solid $gray5;
        border-radius: $progress-bar-height;
        box-shadow: none;
        .progress-bar {
          background-color: $gold;
          border-radius: $progress-bar-height - 4; } }
      .progress-icon {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -($progress-icon-height/2); }
      .progress-text {
        margin-top: $small-padding/2;
        margin-bottom: 0;
        text-align: left;
        font-size: $font-size-h6;
        font-weight: $font-weight-normal; } }
    .progress-help {
      display: inline-block;
      width: 10%;
      vertical-align: middle;
      .help-icon {
        margin-right: $small-padding/2; } } }

  .survey-body {
    padding: $small-padding 0; }

  .survey-question {
    margin-bottom: $big-padding + $normal-padding;
    position: relative;

    &.disabled {
      opacity: 0.2;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10; } }

    &.active {
      opacity: 1; }

    &.completed {
      .survey-selector {
        > li {
          > a:not(.sound-text) {
            cursor: default; } } } }

    h3 {
      height: $survey-title-height;
      margin-bottom: $normal-padding;
      padding-top: $small-padding;
      color: $white;
      background-image: $line-pattern;
      position: relative;

      > span {
        position: relative;
        z-index: 10; }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -($survey-title-triangle/2);
        @include arrow-down($survey-title-triangle/2, $survey-title-height, $normal-black);
        z-index: 1; } }

    .survey-selector {
      margin-bottom: $normal-padding;
      margin-left: 0;
      > li {
        width: 24%;
        padding-left: 0;
        padding-right: 0;
        vertical-align: top;
        @include mobile {
          display: block;
          width: 100%;
          + li {
            margin-top: $small-padding; } }
        > a {
          display: block;
          padding: $small-padding;
          font-size: $font-size-big;
          font-weight: $font-weight-bold;
          color: $normal-black;
          text-decoration: none;
          border: 1px solid $white;
          border-top: 0 none;
          position: relative;
          &:hover {
            border-color: $light-gray; }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: 4px solid $gold; } }
        &.disabled {
          > a {
            color: $light-gray;
            &:hover {
              border-color: $white; }
            &::before {
              border-top-color: $light-gray; } } }

        .sound-text {
          display: block;
          margin: 0 $small-padding;
          padding: 0;
          padding-left: $sound-icon-width + $small-padding;
          font-size: $font-size-h6;
          font-weight: $font-weight-normal;
          font-style: italic;
          text-align: left;
          color: $dark-smoke2;
          background-image: $sound-icon;
          background-position: left 2px;
          background-repeat: no-repeat;
          border: 0 none;
          &::before {
            width: $sound-icon-width;
            height: $sound-icon-height + 2;
            top: 0;
            left: 0;
            border: 0 none; }
          &.playing {
            color: $black;
            @include infinite-animation(speaker-animate, 0.8s);
            &::before {
              display: none; } }
          &:hover {
            color: $black; } } } }

    .form-group-inline {
      max-width: $survey-form-group-width;
      margin: 0 auto;
      padding: $small-padding;
      border-radius: $small-padding;
      position: relative;

      .control-label,
      .form-control {
        width: 48%;
        position: relative;
        @include mobile {
          width: 100%; } }

      .control-label {
        font-size: $font-size-h3;
        font-weight: $font-weight-normal;
        @include mobile {
          margin-bottom: $normal-padding; }
        > .icon-thumbs-up,
        > .icon-thumbs-down {
          margin-right: $small-padding/2;
          margin-bottom: -4px; } }

      .form-control {
        padding: $small-padding $big-padding;
        font-size: $font-size-big;
        font-weight: $font-weight-normal;
        font-style: italic;
        text-align: center;
        color: $lighter-gray;
        background: none;
        border: 0 none;
        border-bottom: 1px solid $gray2; }

      .survey-remove {
        width: $remove-icon-size;
        height: $remove-icon-size;
        font-size: $remove-icon-size;
        line-height: 1.1;
        font-weight: $font-weight-bold;
        border-radius: 50%;
        color: $white;
        background: $normal-black;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: $small-padding;
        display: none;
        @include mobile {
          top: auto;
          bottom: 0;
          margin-bottom: $input-height;
          margin-right: 0; } }

      &.active {
        background-color: $gray4; }

      &.completed {
        .form-control {
          border: 1px solid $light-gray;
          border-top: 4px solid $gold;
          font-style: normal;
          font-weight: $font-weight-semibold;
          color: $normal-black; }
        .survey-remove {
          display: block; } } }

    .survey-help-text {
      margin-bottom: $normal-padding; } } }

@include keyframes(speaker-animate) {
  0% {
    background-image: $sound-icon; }
  50% {
    background-image: $sound-icon-1; }
  100% {
    background-image: $sound-icon-2; } }
