// Accordion

.accordion.panel-group {
  border-bottom: 1px solid $gray6;
  .panel {
    box-shadow: none;
    &.panel-default {
      border: 0 none;
      border-radius: 0;
      .panel-heading {
        padding: 0;
        background: none;
        .panel-title {
          margin: 0;
          font-size: $font-size-h3;
          border-top: 1px solid $gray6;
          > a {
            display: block;
            padding-top: $normal-padding;
            padding-bottom: $normal-padding;
            padding-left: $accordion-icon-size + $normal-padding + ($small-padding/2);
            text-decoration: none;
            position: relative;
            .accordion-icon {
              display: block;
              width: $accordion-icon-size;
              height: $accordion-icon-size;
              background-color: $gray7;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -($accordion-icon-size/2);
              margin-left: $small-padding/2;
              @include transition(background-color 0.25s ease-in);
              &::before,
              &::after {
                content: '';
                display: block;
                width: 18px;
                height: 2px;
                background-color: $white;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -1px;
                margin-left: -9px;
                @include transition(transform 0.25s ease-in); } }
            &.collapsed {
              .accordion-icon {
                background-color: $gold;
                &::before {
                  @include transform(rotate(180deg)); }
                &::after {
                  @include transform(rotate(270deg)); } } } } } }

      .panel-body {
        padding-top: 0;
        padding-left: $small-padding/2;
        border: 0 none;
        p {
          margin: 0; } } } } }
