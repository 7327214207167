// Bootstrap modal override

$modal-width: 600px;

.modal {
  .modal-dialog {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -($modal-width/2);
    border-radius: 0;
    @include mobile {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      background-color: $white;
      .modal-content {
        box-shadow: none;
        border: 0 none; }
      .modal-header {
        .close {
          margin-top: 0;
          margin-right: 0; } } } }

  .modal-content {
    border-radius: 0; }

  .modal-header {
    padding: 0;
    border: 0 none;
    position: relative;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -(36px/2);
      margin-right: -(36px/2);
      opacity: 1;
      filter: none;
      z-index: 10;
      > span {
        display: block;
        width: 36px;
        height: 36px;
        font-size: 26px;
        font-weight: normal;
        line-height: 30px;
        background: $black;
        color: $white;
        text-shadow: none;
        border-radius: 50%;
        border: 3px solid $white;
        -webkit-box-shadow: -3px 3px 2px -2px rgba(185,185,185,1);
        -moz-box-shadow: -3px 3px 2px -2px rgba(185,185,185,1);
        box-shadow: -3px 3px 2px -2px rgba(185,185,185,1); } } }

  .modal-body {
    padding: $big-padding; } }
