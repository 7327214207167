// Variables

// Typo variables
$font-size-h1: 40px;
$font-size-h1-2: 42px;
$font-size-h1-big: 48px;
$font-size-h2: 36px;
$font-size-h3: 22px;
$font-size-h3-big: 30px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;
$font-size-normal: 16px;
$font-size-big: 18px;
$font-size-small: 13px;
$font-size-smaller: 11px;

// Fonts
$default-font-family: 'Open Sans';
$header-font-family: 'Lato';
$bs-glyphicon: 'Glyphicons Halflings';

// Font weights
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

// Color
$white: #ffffff;
$black: #000000;
$normal-black: #333333;
$dark-black: #212121;
$blue: #2c71e3;
$orange: #ec5300;
$dark-orange: #c64601;
$gold: #b2965f;
$gray: #636363;
$dark-gray: #343434;
$light-gray: #d6d6d6;
$lighter-gray: #c6c5c5;
$light-gray2: #f9f9f9;
$gray1: #b1b1b1;
$dark-gray1: #999999;
$gray2: #bbbbbb;
$gray3: #e1e1e1;
$gray4: #e8e8e8;
$gray5: #dddddd;
$gray6: #cccccc;
$gray7: #c0c1c2;
$smoke: #f2f2f2;
$light-smoke: #f7f7f7;
$lighter-smoke: #fafafa;
$dark-smoke: #727272;
$dark-smoke2: #7d7d7d;
$chart-blue: #0088cf;
$chart-yellow: #ffca05;
$chart-green: #50b847;
$chart-red: #ec1c23;
$dark-blue: #161b22;

// Sizes
$inner-container-width: 943px;
$small-padding: 10px;
$normal-padding: 20px;
$big-padding: 30px;
$header-height: 110px;
$header-active-height: 84px;
$logo-size: 74px;
$logo-small-size: 65px;
$input-height: 40px;
$input-height-big: 50px;
$btn-height: 45px;
$btn-padding: 50px;
$checkbox-size: 18px;
$checkbox-size-big: 24px;
$radio-size: 16px;
$radio-inner-size: 8px;
$input-padding: 12px;
$selectbox-arrow-size: 36px;
$selectbox-arrow-width: 16px;
$selectbox-arrow-height: 8px;
$login-form-width: 378px;
$login-padding-top: 175px;
$login-padding-bottom: 137px;
$pattern-size: 4px;
$thumb-icon-width: 30px;
$thumb-icon-height: 25px;
$remove-icon-size: 20px;
$help-icon-size: 20px;
$sound-icon-width: 20px;
$sound-icon-height: 17px;
$progress-bar-height: 16px;
$progress-icon-width: 35px;
$progress-icon-height: 44px;
$chart-circle-size: 56px;
$chart-size: 284px;
$accordion-icon-size: 32px;
$icon-v-size: 42px;
