// Bootstrap Tooltip Override

.tooltip {
  .tooltip-inner {
    max-width: 500px;
    padding: 10px 20px;
    font-family: $default-font-family;
    font-size: $font-size-h6;
    font-weight: $font-weight-normal;
    text-align: left;
    color: $white;
    background-color: #514f4f; }
  &.top {
    margin-top: -8px;
    .tooltip-arrow {
      margin-bottom: -6px;
      margin-left: -8px;
      border-width: 12px 8px 0;
      border-top-color: #514f4f; } } }
