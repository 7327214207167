// Sass Mixins

// hover and focus
@mixin hover-focus {
  &:focus,
  &:hover,
  &:active,
  &:active:focus,
  &:active:hover {
    @content; } }

// Clearfix
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: '';
    display: table;
    line-height: 0; }
  &:after {
    clear: both; } }

@mixin arrow-up($width, $height, $color) {
  width: 0;
  height: 0;
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-bottom: $height solid $color; }

@mixin arrow-down($width, $height, $color) {
  width: 0;
  height: 0;
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-top: $height solid $color; }

// transition
@mixin transition($value...) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  transition: $value; }

// transform
@mixin transform($value...) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value; }

@mixin appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none; }

// Animation
@mixin infinite-animation($value, $time) {
  -webkit-animation: $value $time infinite;
  -moz-animation:    $value $time infinite;
  -o-animation:      $value $time infinite;
  animation:         $value $time infinite; }

// Keyframe
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-o-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }

// responsive mobile
@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content; } }

// responsive tablet
@mixin tablet {
  @media only screen and (max-width: 991px) {
    @content; } }
