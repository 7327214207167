// Result page

.result {}

.result-body {
  padding-top: $normal-padding*2;
  padding-bottom: $normal-padding + $big-padding;

  h2 {
    margin-top: $big-padding;
    margin-bottom: $normal-padding;
    font-weight: $font-weight-bold; }

  ul {
    margin-bottom: $normal-padding;
    padding-left: $normal-padding - ($small-padding/2);
    > li {
      padding-left: $small-padding/2; } } }

.result-header {
  padding-top: $normal-padding + $big-padding;
  padding-bottom: $normal-padding + $big-padding;
  background-color: $light-gray2;
  h1 {
    text-align: center;
    margin-bottom: $normal-padding + $big-padding; } }

.result-header-top {
  padding-top: $big-padding;
  padding-bottom: $big-padding;
  p {
    margin-bottom: 0; } }

.report-box {
  margin-top: $big-padding;
  margin-bottom: $big-padding;
  padding-left: $normal-padding;
  padding-right: $normal-padding;
  ul {
    margin-top: $normal-padding;
    margin-bottom: 0;
    > li {
      padding: 0 $normal-padding*2;
      @include mobile {
        display: block;
        margin: $normal-padding; } } } }

.result-footer {
  padding-top: $big-padding + $normal-padding;
  padding-bottom: $big-padding + $normal-padding;
  color: $white;
  background-color: $dark-blue;

  .result-thumbnail {
    @include tablet {
      margin-bottom: $normal-padding*2; }
    @include mobile {
      margin-bottom: $normal-padding*2;
      .img-responsive {
        width: 100%;
        height: auto; } } }

  h2 {
    margin-top: $small-padding;
    margin-bottom: $big-padding;
    text-align: center;
    font-weight: $font-weight-bold; }

  a {
    color: $white; } }
