// Overall page settings

// .main
//   margin-top: -$header-height
//   padding-top: $header-height

.container-inner {
  max-width: $inner-container-width;
  margin-left: auto;
  margin-right: auto; }

.bg-pattern {
  padding-top: $normal-padding;
  padding-bottom: $big-padding;
  background-color: $light-smoke;
  background-image: repeating-linear-gradient(45deg, transparent, transparent $pattern-size, $smoke $pattern-size, $smoke ($pattern-size*2));
  background-image: $line-pattern;
  border-radius: 0; }
