// Login

.login-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  background-color: $black;
  background-image: $login-bg;
  background-position: center top;
  background-size: cover;
  position: relative;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  @include mobile {
    padding-top: $normal-padding + $header-height;
    padding-bottom: $normal-padding; } }

.login-area {
  text-align: center;
  color: $white;
  @include clearfix;

  .login-form {
    margin-top: $normal-padding; }

  .form-group {
    margin-left: auto;
    margin-right: auto;
    max-width: $login-form-width;
    &:not(.text-center) {
      text-align: left; }

    .control-label {
      font-weight: $font-weight-normal;
      color: $normal-black; }

    .form-control {
      height: $input-height-big; } }

  .checkbox {
    min-height: $checkbox-size-big;
    padding-left: $checkbox-size-big + $small-padding;
    input[type='checkbox'] {
      + span {
        width: $checkbox-size-big;
        height: $checkbox-size-big;
        line-height: $checkbox-size-big;
        margin-top: 0;
        &::before {
          font-size: 0.8em;
          line-height: 1.6; } } } }

  h1 {
    font-size: $font-size-h1-big;
    font-weight: $font-weight-extrabold; }

  h1,
  h5 {
    text-shadow: 0px 0px 8px rgba($black, 0.2); }

  a {
    color: $white;
    text-decoration: underline;
    @include hover-focus {
      color: $white; } } }
