// Print style

@media print {
  // Hidden elements on print
  .no-print-area,
  .report-box,
  .result-footer {
    display: none; }

  // Print area
  .print-area {
    display: block;
    &.no-print-area {
      display: none; } }

  *,
  body,
  p {
    font-size: 14px; }
  a {
    color: $blue !important; }
  h1 {
    font-size: 30px;
    > span {
      font-size: inherit; } }
  h2 {
    font-size: 22px; }
  h3,
  .chart-title {
    font-size: 18px; }

  // Logo link remove
  .logo-container::after {
    content: none !important; }

  // Header
  header,
  .header {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 110px solid $normal-black; }
    .hidden-xs,
    .col-sm-6 {
      display: inline-block !important; }
    h3 {
      color: $white !important;
      text-shadow: 0 0 0 $white;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact; } }

  footer,
  .footer {
    border-top: 1px solid $normal-black;
    ul.list-inline {
      display: none; } }

  .legend-circle {
    color: $white !important;
    text-shadow: 0 0 0 $white;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    text-indent: -1em;
    line-height: 0px;
    &.blue {
      border: $chart-circle-size/2 solid $chart-blue; }
    &.green {
      border: $chart-circle-size/2 solid $chart-green; }
    &.red {
      border: $chart-circle-size/2 solid $chart-red; }
    &.yellow {
      border: $chart-circle-size/2 solid $chart-yellow; } } }
