// Additional form
$additional-max-width: 750px;
$textarea-height: 196px;

.pdi-additional {
  margin-top: $normal-padding + $big-padding;
  margin-bottom: $big-padding;
  @include clearfix;

  .jumbotron {
    @extend .bg-pattern;

    h2 {
      margin-top: 0;
      font-weight: $font-weight-extrabold; }

    h4 {
      margin-bottom: 0;
      max-width: $additional-max-width;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.25; } }

  .form-group {
    max-width: $additional-max-width;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $big-padding;

    textarea {
      min-height: $textarea-height; }

    ul:not(.list-inline) {
      > li {
        .checkbox,
        .radio {
          margin: 0; }
        + li {
          margin-top: $small-padding/2; } } } } }
