// Chart and chart legends

.chart-box {
  display: block;
  width: $chart-size;
  height: $chart-size;
  margin: $small-padding auto;
  @include mobile {
    margin-bottom: $normal-padding; } }

.chart-title {
  margin-top: 0;
  margin-bottom: $normal-padding;
  font-size: $font-size-h3-big;
  @include mobile {
    text-align: center; } }

.chart-legend {
  > li {
    display: table;
    width: 100%;
    position: relative;
    height: $chart-circle-size;
    padding-left: $chart-circle-size + $small-padding;
    + li {
      margin-top: $small-padding; }
    .legend-circle {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -($chart-circle-size/2);
      display: table-cell;
      color: $white;
      vertical-align: middle; }
    > p {
      display: table-cell;
      vertical-align: middle; } } }

.legend-circle {
  display: block;
  width: $chart-circle-size;
  height: $chart-circle-size;
  line-height: $chart-circle-size;
  text-align: center;
  vertical-align: middle;
  color: $white;
  font-family: $header-font-family;
  font-size: $font-size-normal;
  font-weight: $font-weight-bold;
  border-radius: 50%;
  position: relative;
  &.blue {
    background-color: $chart-blue; }
  &.green {
    background-color: $chart-green; }
  &.red {
    background-color: $chart-red; }
  &.yellow {
    background-color: $chart-yellow; } }
